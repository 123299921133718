@import ../../../styles/helpers

.hero
    height: 14200px
    +x
        min-height: 16000px
    +d
        min-height: 17000px
    +m
        min-height: 20000px
        padding: 64px 0 152px