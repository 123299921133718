@use "sass:math"

.hero,
.h1,
.h2,
.h3,
.h4,
    +sf-pro
    font-weight: 600

.hero
    font-size: 96px
    line-height: 1
    letter-spacing: -.02em
    +m
        font-size: 64px

.h1
    font-size: 58px
    line-height: 1
    letter-spacing: -.02em
    font-weight: 600

    +t
        font-size: 48px
        line-height: math.div(56px, 48px)

.h2
    font-size: 48px
    line-height: math.div(56px, 48px)
    letter-spacing: -.02em
    +t
        font-size: 40px
        line-height: math.div(48px, 40px)

.h3
    font-size: 38px
    line-height: math.div(56px, 48px)
    letter-spacing: -.01em
    font-weight: 600

    +t
        font-size: 32px
        line-height: math.div(40px, 32px)

.h4
    font-size: 32px
    line-height: math.div(40px, 32px)
    letter-spacing: -.01em