// media queries
=w
  @media only screen and (max-width: "2000px")
    @content
=x
  @media only screen and (max-width: "2000px")
    @content
=d
  @media only screen and (max-width: "1159px")
    @content
=t
  @media only screen and (max-width: "1023px")
    @content
=m
  @media only screen and (max-width: "767px")
    @content
=a
  @media only screen and (max-width: "639px")
    @content
=s
  @media only screen and (max-width: "474px")
    @content

// fonts
=poppins
  font-family: 'Poppins', sans-serif

=sf-pro-display
  font-family: 'SFProDisplay-Bold'
  
// colors
$white: #ffffff
$red: #FF592C
$purple: #9757D7
$purple-light: #CDB4DB
$pink: #EF466F
$green: #45B26B
$blue: #3772FF
$asphalt: #E4D7CF
$yellow: #FFD166

$neutrals1: #141416
$neutrals2: #23262F
$neutrals3: #353945
$neutrals4: #777E90
$neutrals5: #B1B5C3
$neutrals6: #E6E8EC
$neutrals7: #F4F5F6
$neutrals8: #FCFCFD

// typography
=body-1
  font-size: 24px
  line-height: (32/24)
  letter-spacing: -.01em

=body-2
  font-size: 24px
  line-height: (32/24)
  font-weight: 600

=caption-1
  font-size: 14px
  line-height: (24/14)

=caption-2
  font-size: 12px
  line-height: (20/12)

=hairline-1
  line-height: 1
  font-weight: 700
  text-transform: uppercase

=hairline-2
  font-size: 12px
  line-height: 1
  font-weight: 700
  text-transform: uppercase

=button-1
  +sf-pro-display
  font-size: 16px
  font-weight: 700
  line-height: 1

=button-2
  +sf-pro-display
  font-size: 14px
  line-height: (16/14)
  font-weight: 700
  
//triangle
=arr($width, $height, $bg, $direction)
  width: 0px
  height: 0px
  border-style: solid
  @if $direction == t
    border-width: 0 $width / 2 + px $height + px $width / 2 + px
    border-color: transparent transparent $bg transparent
  @if $direction == r
    border-width: $height / 2 + px 0 $height / 2 + px $width + px
    border-color: transparent transparent transparent $bg
  @if $direction == b
    border-width: $height + px $width / 2 + px 0 $width / 2 + px
    border-color: $bg transparent transparent transparent
  @if $direction == l
    border-width: $height / 2 + px $width + px $height / 2 + px 0
    border-color: transparent $bg transparent transparent
  @if $direction == tl
    border-width: $height + px $width + px 0 0
    border-color: $bg transparent transparent transparent
  @if $direction == tr
    border-width: 0 $width + px $height + px 0
    border-color: transparent $bg transparent transparent
  @if $direction == br
    border-width: 0 0 $height + px $width + px
    border-color: transparent transparent $bg transparent
  @if $direction == bl
    border-width: $width + px 0 0 $height + px
    border-color: transparent transparent transparent $bg
// example
// +arr(500,50, #000, l)
// +arr(500,50, #000, bl)

// placeholder
=placeholder
  &::placeholder
    @content

// media query width
=r($width)
  @media only screen and (max-width: $width+ "px")
    @content

=rmin($width)
  @media only screen and (min-width: $width+ "px")
    @content

// theme
=dark
  @at-root :global(.dark-mode) &
    @content

=dark-body
  @at-root .dark-mode
    @content

=dark-common
  @at-root .dark-mode &
    @content